/**
 * @prettier
 */

document.addEventListener('DOMContentLoaded', () => {
  const s3Path = document.querySelector('.js-s3-data').dataset.directHostAlias
  const styles = document.querySelectorAll("link[rel='stylesheet']")
  const stylesLength = styles.length
  const jsTags = document.querySelectorAll('script[src]')
  const jsTagsLength = jsTags.length
  const images = document.querySelectorAll('img')
  const imagesLength = images.length
  const blockedUrl = document.querySelector('.js-s3-data').dataset.hostAlias
  const DIGEST = '1497009765042'

  function getFallbackUrl(url) {
    return url.replace(/blockedUrl || '""'}/, s3Path)
  }

  function loadStyleSheets() {
    for (let i = 0; i < stylesLength; i++) {
      let originalLink = styles[i].href

      if (originalLink && originalLink.includes(blockedUrl)) {
        let fileUrl = getFallbackUrl(originalLink)
        let link = document.createElement('link')

        link.rel = 'stylesheet'
        link.type = 'text/css'
        link.href = fileUrl + '?v=' + DIGEST
        link.media = 'all'
        document.head.appendChild(link)
      }
    }
  }

  function loadJS() {
    for (let i = 0; i < jsTagsLength; i++) {
      let originalLink = jsTags[i].src

      if (originalLink && originalLink.includes(blockedUrl)) {
        let fileUrl = getFallbackUrl(originalLink)
        let script = document.createElement('script')

        script.type = 'text/javascript'
        script.src = fileUrl + '?v=' + DIGEST
        document.body.appendChild(script)
      }
    }
  }

  function replaceImages() {
    for (let i = 0; i < imagesLength; i++) {
      let originalLink = images[i].src

      if (originalLink && originalLink.includes(blockedUrl)) {
        let fileUrl = getFallbackUrl(originalLink)
        let path = fileUrl + '?v=' + DIGEST

        images[i].src = path
      }
    }
  }

  document.addEventListener('readystatechange', function () {
    if (document.readyState === 'interactive' && typeof $ === 'undefined') {
      loadStyleSheets()
      loadJS()
      replaceImages()
      window.Bugsnag &&
        Bugsnag.notify({
          name: 'CDN fallback',
          message: 'failed to load assets',
        })
    }
  })
})
